import React, { useContext } from "react"
import { AppContext } from "../../contexts/AppContext"
import { DEVICE_MOBILE, DEVICE_LANDSCAPE } from "../../constants/breakpoints"
import { ContentWrapper } from "../Styled"
import SEO from "../../components/Seo"
import MobileCountrySlider from "../ContentHome/mobileCountrySlider"
import DesktopCountrySlider from "../ContentHome/DesktopCountrySlider"

const ContentHome = ({
  seoTitle,
  seoDescription,
  seoImage,
  seoImageWidth,
  seoImageHeight,
}) => {
  let { device, selectedCountry } = useContext(AppContext)
  return (
    <>
      {device === DEVICE_MOBILE || device === DEVICE_LANDSCAPE
        ? selectedCountry && (
            <ContentWrapper>
              <MobileCountrySlider />
            </ContentWrapper>
          )
        : selectedCountry && (
            <ContentWrapper style={{ paddingTop: "0px" }}>
              <SEO
                {...{
                  seoTitle,
                  seoDescription,
                  seoImage,
                  seoImageWidth,
                  seoImageHeight,
                }}
              />
              <DesktopCountrySlider />
            </ContentWrapper>
          )}
    </>
  )
}

export default ContentHome
