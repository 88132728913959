import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby-link"
import {Link} from "gatsby"
import { AppContext } from "../../contexts/AppContext"
import {
  convertToSlug,
  stationsPageURL,
  COUNTRY_ALL,
} from "../../constants/props"
import {
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"
import {
  PROP_FONT_PRIMARY
} from "../../constants/props"


const CountrySliderParent = styled.div`
  width: 100%;
`
const CountrySearchContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  letter-spacing: 1.5px;
  margin: 0 auto;
  font-family: ${PROP_FONT_PRIMARY};
  z-index: 2;
  margin-bottom: ${TAB.countrySlider.search.marginBottom}px;
  @media (min-width: ${MEDIUM.minWidth}px) {
     margin-bottom: ${MEDIUM.countrySlider.search.marginBottom}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    margin-bottom: ${LARGE.countrySlider.search.marginBottom}px;
  }
`
const MainHeading = styled.h2`
  text-align: center;
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: ${TAB.countrySlider.heading.font}px;
  padding-top: ${TAB.countrySlider.heading.paddingTop}px;
  padding-bottom: ${TAB.countrySlider.heading.paddingTop}px;

  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.countrySlider.heading.font}px;
    padding-top: ${MEDIUM.countrySlider.heading.paddingTop}px;
    padding-bottom: ${MEDIUM.countrySlider.heading.paddingTop}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.countrySlider.heading.font}px;
    padding-top: ${LARGE.countrySlider.heading.paddingTop}px;
    padding-bottom: ${LARGE.countrySlider.heading.paddingTop}px;
  }
`

const SubHeading = styled.h2`
  font-size: 20px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.countrySlider.heading.subHeadingFont}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.countrySlider.heading.subHeadingFont}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.countrySlider.heading.subHeadingFont}px;
  }
`

const MainText = styled.h1`
  font-size: 20px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.countrySlider.heading.subTextFont}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.countrySlider.heading.subTextFont}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.countrySlider.heading.subTextFont}px;
  }
`


const SubText = styled.h3`
  font-size: 20px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.countrySlider.heading.subTextFont}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.countrySlider.heading.subTextFont}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.countrySlider.heading.subTextFont}px;
  }
`
 
const FlagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  width: ${TAB.countrySlider.flag.small.flagRow}px;
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.countrySlider.flag.small.flagRow}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.countrySlider.flag.small.flagRow}px;
  }
`

const Flag = styled(Link)`
  padding: ${props =>
      props.selected
        ? TAB.countrySlider.flag.small.padding -
          TAB.countrySlider.flag.small.selectedHeightIncrease / 2
        : TAB.countrySlider.flag.small.padding}px
    ${props =>
      props.selected
        ? TAB.countrySlider.flag.small.padding -
          TAB.countrySlider.flag.small.selectedWidthIncrease / 2
        : TAB.countrySlider.flag.small.padding}px;

  box-shadow: ${props =>
    props.selected ? "rgba(255, 255, 255, 0.4) 0px 10px 5px -8px" : "none"};

  &:hover {
    text-decoration: none;
  }

  & h3 {
    width: ${TAB.countrySlider.flag.small.width}px;
    color: white;
    font-size: ${TAB.countrySlider.flag.small.font}px;
    padding-top: 5px;
    font-family: ${PROP_FONT_PRIMARY};
    margin: 0 auto;
    text-align: center;
  }
  & div {
    margin: 0 auto;
    width: ${props =>
      props.selected
        ? TAB.countrySlider.flag.small.width +
          TAB.countrySlider.flag.small.selectedWidthIncrease
        : TAB.countrySlider.flag.small.width}px;
    height: ${props =>
      props.selected
        ? TAB.countrySlider.flag.small.height +
          TAB.countrySlider.flag.small.selectedHeightIncrease
        : TAB.countrySlider.flag.small.height}px;
  }

  & img {
    cursor: pointer;
    width: 100%;
    height: 100%;
    border-radius: ${TAB.countrySlider.flag.small.borderRadius}px;
  }

  @media (min-width: ${MEDIUM.minWidth}px) {
    padding: ${props =>
        props.selected
          ? MEDIUM.countrySlider.flag.small.padding -
            MEDIUM.countrySlider.flag.small.selectedHeightIncrease / 2
          : MEDIUM.countrySlider.flag.small.padding}px
      ${props =>
        props.selected
          ? MEDIUM.countrySlider.flag.small.padding -
            MEDIUM.countrySlider.flag.small.selectedWidthIncrease / 2
          : MEDIUM.countrySlider.flag.small.padding}px;
    & h3 {
      width: ${MEDIUM.countrySlider.flag.small.width}px;
      font-size: ${MEDIUM.countrySlider.flag.small.font}px;
    }
    & div {
      width: ${props =>
        props.selected
          ? MEDIUM.countrySlider.flag.small.width +
            MEDIUM.countrySlider.flag.small.selectedWidthIncrease
          : MEDIUM.countrySlider.flag.small.width}px;
      height: ${props =>
        props.selected
          ? MEDIUM.countrySlider.flag.small.height +
            MEDIUM.countrySlider.flag.small.selectedHeightIncrease
          : MEDIUM.countrySlider.flag.small.height}px;
    }
    & img {
      border-radius: ${MEDIUM.countrySlider.flag.small.borderRadius}px;
    }
  }

  @media (min-width: ${LARGE.minWidth}px) {
    padding: ${props =>
        props.selected
          ? LARGE.countrySlider.flag.small.padding -
            LARGE.countrySlider.flag.small.selectedHeightIncrease / 2
          : LARGE.countrySlider.flag.small.padding}px
      ${props =>
        props.selected
          ? LARGE.countrySlider.flag.small.padding -
            LARGE.countrySlider.flag.small.selectedWidthIncrease / 2
          : LARGE.countrySlider.flag.small.padding}px;
    & h3 {
      width: ${LARGE.countrySlider.flag.small.width}px;
      font-size: ${LARGE.countrySlider.flag.small.font}px;
    }
    & div {
      width: ${props =>
        props.selected
          ? LARGE.countrySlider.flag.small.width +
            LARGE.countrySlider.flag.small.selectedWidthIncrease
          : LARGE.countrySlider.flag.small.width}px;
      height: ${props =>
        props.selected
          ? LARGE.countrySlider.flag.small.height +
            LARGE.countrySlider.flag.small.selectedHeightIncrease
          : LARGE.countrySlider.flag.small.height}px;
    }
    & img {
      border-radius: ${LARGE.countrySlider.flag.small.borderRadius}px;
    }
  }
`
const DesktopCountrySlider = props => {
  const {
    countries,
    setSelectedCountry,
    selectedCountry,
    setShowLoader,
  } = useContext(AppContext)

  let slider = null
  const [currentIdx, setCurrentIdx] = useState(-1)
  const [allCountryIdx, setAllCountryIdx] = useState(-1)

  const selectedCountryID = selectedCountry ? selectedCountry.value : null

  useEffect(() => {
    const currentIndex = countries.findIndex(c => {
      return c.value === selectedCountryID
    })

    if (currentIdx !== currentIndex && slider) {
      if (
        (currentIdx === 0 && currentIndex === countries.length - 1) ||
        (currentIndex === 0 && currentIdx === countries.length - 1)
      ) {
        slider.slickGoTo(currentIndex, true) // by setting true we can stop large scroll
      } else {
        slider.slickGoTo(currentIndex, false)
      }

      setCurrentIdx(currentIndex)
    }
  }, [selectedCountryID])

  useEffect(() => {
    const allIdx = countries.findIndex(c => {
      return c.name === COUNTRY_ALL
    })
    setAllCountryIdx(allIdx)
  }, [])

  return (
    <CountrySliderParent>
      <CountrySearchContainer>
        <MainHeading>WELCOME TO STREAM AFRICA</MainHeading>
        <MainText>AFRICA'S FAVORITE ONLINE RADIO PLATFORM</MainText>
        <SubHeading>CLICK A FLAG BELOW</SubHeading>
        <SubText>AND LISTEN TO LIVE ONLINE RADIO ACROSS AFRICA</SubText>
      </CountrySearchContainer>

      <FlagsWrapper>
        {countries.map((country, i) => (
          <Flag
            selected={selectedCountry.id === country.id}
            to={'/radios/'+country.slug}
            alt={country.name}
            key={`flag_${i}`}
            onClick={() => {
              setSelectedCountry({ ...country })
              setShowLoader(true)
              setTimeout(() => {
                setShowLoader(false)
              }, 1000)
            }}
          >
            <div>
              <img src={country.url} alt={country.name}></img>
            </div>
            <h3>{country.name}</h3>
          </Flag>
        ))}
      </FlagsWrapper>
    </CountrySliderParent>
  )
}

export default DesktopCountrySlider
