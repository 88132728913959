import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { AppContext } from "../../contexts/AppContext"
import {
  COUNTRY_ALL,
  PROP_FONT_PRIMARY,
} from "../../constants/props"
import { MOBILE, LANDSCAPE } from "../../constants/breakpoints"

const CountrySliderParent = styled.div`
  width: 100vw;
`
const CountrySearchContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: ${PROP_FONT_PRIMARY};
  margin: 0 auto;
  width: ${MOBILE.countrySlider.search.width}vw;
  margin-bottom: ${MOBILE.countrySlider.search.marginBottom}px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.countrySlider.search.width}vw;
    margin-bottom: ${LANDSCAPE.countrySlider.search.marginBottom}px;
  }
`
const MainHeading = styled.h1`
  text-align: center;
  color: #fff;
  font-size: ${MOBILE.countrySlider.heading.font}px;
  padding-top: ${MOBILE.countrySlider.heading.paddingTop}px;
  padding-bottom: ${MOBILE.countrySlider.heading.paddingTop}px;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.countrySlider.heading.font}px;
    padding-top: ${LANDSCAPE.countrySlider.heading.paddingTop}px;
    padding-bottom: ${LANDSCAPE.countrySlider.heading.paddingTop}px;
  }
`
const SubHeading = styled.h1`
  font-size: ${MOBILE.countrySlider.heading.subHeadingFont}px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.countrySlider.heading.subHeadingFont}px;
  }
`
const SubText = styled.h2`
  font-size: ${MOBILE.countrySlider.heading.subTextFont}px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.countrySlider.heading.subTextFont}px;
  }
`

// countries flags

const FlagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
  width: ${MOBILE.countrySlider.flag.small.flagRow}vw;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.countrySlider.flag.small.flagRow}vw;
  }
`

const Flag = styled(Link)`
  padding: ${props =>
      props.selected
        ? MOBILE.countrySlider.flag.small.padding -
          MOBILE.countrySlider.flag.small.selectedHeightIncrease / 2
        : MOBILE.countrySlider.flag.small.padding}vw
    ${props =>
      props.selected
        ? MOBILE.countrySlider.flag.small.padding -
          MOBILE.countrySlider.flag.small.selectedWidthIncrease / 2
        : MOBILE.countrySlider.flag.small.padding}vw;

  box-shadow: ${props =>
    props.selected ? "0px 11px 10px -8px rgba(255, 255, 255, 0.4)" : "none"};

  &:hover {
    text-decoration: none;
  }

  & h3 {
    width: ${MOBILE.countrySlider.flag.small.width}vw;
    color: white;
    font-size: ${MOBILE.countrySlider.flag.small.font}px;
    padding-top: 5px;
    font-family: ${PROP_FONT_PRIMARY};
    margin: 0 auto;
    text-align: center;
  }
  & div {
    margin: 0 auto;
    width: ${props =>
      props.selected
        ? MOBILE.countrySlider.flag.small.width +
          MOBILE.countrySlider.flag.small.selectedWidthIncrease
        : MOBILE.countrySlider.flag.small.width}vw;
    height: ${props =>
      props.selected
        ? MOBILE.countrySlider.flag.small.height +
          MOBILE.countrySlider.flag.small.selectedHeightIncrease
        : MOBILE.countrySlider.flag.small.height}vw;
  }

  & img {
    width: 100%;
    height: 100%;
    border-radius: ${MOBILE.countrySlider.flag.small.borderRadius}px;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    padding: ${props =>
        props.selected
          ? LANDSCAPE.countrySlider.flag.small.padding -
            LANDSCAPE.countrySlider.flag.small.selectedHeightIncrease / 2
          : LANDSCAPE.countrySlider.flag.small.padding}vw
      ${props =>
        props.selected
          ? LANDSCAPE.countrySlider.flag.small.padding -
            LANDSCAPE.countrySlider.flag.small.selectedWidthIncrease / 2
          : LANDSCAPE.countrySlider.flag.small.padding}vw;
    & h3 {
      width: ${LANDSCAPE.countrySlider.flag.small.width}vw;
      font-size: ${LANDSCAPE.countrySlider.flag.small.font}px;
    }
    & div {
      width: ${props =>
        props.selected
          ? LANDSCAPE.countrySlider.flag.small.width +
            LANDSCAPE.countrySlider.flag.small.selectedWidthIncrease
          : LANDSCAPE.countrySlider.flag.small.width}vw;
      height: ${props =>
        props.selected
          ? LANDSCAPE.countrySlider.flag.small.height +
            LANDSCAPE.countrySlider.flag.small.selectedHeightIncrease
          : LANDSCAPE.countrySlider.flag.small.height}vw;
    }
    & img {
      border-radius: ${LANDSCAPE.countrySlider.flag.small.borderRadius}px;
    }
  }
`
const MobileCountrySlider = props => {
  const {
    countries,
    setSelectedCountry,
    selectedCountry,
    setShowLoader,
  } = useContext(AppContext)

  let slider = null

  const [currentIdx, setCurrentIdx] = useState(-1)
  const [allCountryIdx, setAllCountryIdx] = useState(-1)

  useEffect(() => {
    const currentIndex = countries.findIndex(c => {
      return c.value === selectedCountry.value
    })

    if (currentIdx !== currentIndex && slider) {
      slider.slickGoTo(currentIndex, false)
      setCurrentIdx(currentIndex)
    }
  }, [selectedCountry.id])

  useEffect(() => {
    const allIdx = countries.findIndex(c => {
      return c.name === COUNTRY_ALL
    })
    setAllCountryIdx(allIdx)
  }, [])

  return (
    <CountrySliderParent>
      <CountrySearchContainer>
        <MainHeading>WELCOME TO STREAM AFRICA</MainHeading>
        <SubText>AFRICA'S FAVORITE ONLINE RADIO PLATFORM</SubText>
        <SubHeading>CLICK A FLAG BELOW</SubHeading>
        <SubText>AND LISTEN TO LIVE ONLINE RADIO ACROSS AFRICA</SubText>
      </CountrySearchContainer>

      <FlagsWrapper>
        {countries.map((country, i) => (
          <Flag
            selected={selectedCountry.id === country.id}
            key={`flag_${i}`}
            to={"/radios/" + country.slug}
            onClick={() => {
              setSelectedCountry({ ...country })
              setShowLoader(true)
              setTimeout(() => {
                setShowLoader(false)
              }, 1000)
            }}
          >
            <div>
              <img src={country.url} alt={country.name}></img>
            </div>
            <h3>{country.name}</h3>
          </Flag>
        ))}
      </FlagsWrapper>
    </CountrySliderParent>
  )
}

export default MobileCountrySlider
